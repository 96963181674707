<template>
  <div class="authorization-apps">
    <div class="top-buttons">
      <el-button v-power="`40-10-20`" icon="el-icon-key" type="danger" size="mini" @click="doAddWxAppByAuth">授权
      </el-button>
    </div>

    <div class="search-form">
      <el-form inline :model="searchForm" size="mini" label-suffix=":">
        <el-form-item label="搜索">
          <el-input name="searchToken" v-model="searchForm.searchToken" placeholder="请输入文字查询" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadAppList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :loading="tableData.loading" element-loading-spinner="el-icon-loading" element-loading-text="拼命加载中..."
      :data="appData.rows" row-class-name="app-item" row-key="id">

      <el-table-column header-align="center" label="头像" width="80">
        <template slot-scope="scope">
          <div style="width: 60px; height: 60px">
            <el-image class="image" referrerpolicy="no-referrer" :preview-src-list="[scope.row.headImg]"
              :src="scope.row.headImg" fit="cover" />
          </div>
        </template>
      </el-table-column>

      <el-table-column label="基本信息" header-align="center">
        <template slot-scope="scope">
          <div class="name-info">
            <div class="nickname">
              {{ scope.row.nickName + (scope.row.alias ? "(" + row.alias + ")" : "") }}
              <el-tag v-if="scope.row.defaultFlag == 1" type="primary" size="mini">默认</el-tag>
              <el-tag v-if="scope.row.authorizationStatus == 1" type="success" size="mini">授权中</el-tag>
              <el-tag v-if="scope.row.authorizationStatus == 0" type="danger" size="mini">已取消</el-tag>
            </div>
            <div class="signature" :title="scope.row.signature">{{ maxLength(scope.row.signature, 20) }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="经营主体" header-align="center" width="300">
        <template slot-scope="scope">
          <div class="principal-info">
            <div class="name">
              <div :title="scope.row.principalName">
                {{ maxLength(scope.row.principalName, 10) }}
              </div>
              <div class="tag">
                <el-tag type="primary" size="mini">{{ scope.row._caption.verifyTypeId }}</el-tag>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="开发" header-align="center">
        <template slot-scope="scope">
          <div class="develop-info">
            <div class="appid">
              <div class="label">APPID:</div>
              <div class="value">{{ scope.row.authorizerAppid }}</div>
            </div>
            <div class="userName">
              <div class="label">原始ID:</div>
              <div class="value">{{ scope.row.userName }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="分享" header-align="center" width="80">
        <template slot-scope="scope">
          <div class="share-info">
            <div class="qr-code">
              <el-image :preview-src-list="[scope.row.qrcodeUrl]" referrerpolicy="no-referrer" :src="scope.row.qrcodeUrl"
                fit="cover" />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" header-align="center" width="100">
        <template slot-scope="scope">
          <div style="text-align: center;">
            <el-button type="text" @click="setDefault(scope.row)">设为默认</el-button>
            <el-button type="text" @click="setMain(scope.row)">设为主要</el-button>
            <el-button type="text" @click="setMainMp(scope.row)">设为主要(MP)</el-button>
          </div>
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
export default {
  name: 'authorization-apps',
  data() {
    return {
      tableData: {
        loading: false,
      },
      searchForm: {
        searchToken: null
      },
      appData: {

      }
    }
  },
  methods: {
    maxLength(str, maxLength) {
      if (!str || str.length <= maxLength) {
        return str;
      }
      return str.substring(0, maxLength) + "...";
    },
    async setDefault({ id }) {
      try {
        await this.$http.doApi('fw-wx-authorizer-setDefault', { id });
        this.$message.success('设置成功');
      } catch (e) {
        console.log(e);
        this.$message.error('设置失败');
      }
      this.loadAppList();
    },
    async setMain({ id }) {
      try {
        await this.$http.doApi('fw-wx-authorizer-setMain', { id });
        this.$message.success('设置成功');
      } catch (e) {
        console.log(e);
        this.$message.error('设置失败')
      }
      this.loadAppList();
    },
    async setMainMp({ id }) {
      try {
        await this.$http.doApi('fw-wx-authorizer-setMainMp', { id });
        this.$message.success('设置成功');
      } catch (e) {
        console.log(e);
        this.$message.error('设置失败')
      }
      this.loadAppList();
    },
    async doAddWxAppByAuth() {
      let data = await this.$http.doApi('fw-wx-authorizer-getAuthorizationUrl');
      window.open(data.val, "_blank");
      await this.$confirm("是否完成授权？", "提示", {
        confirmButtonText: "已授权",
        cancelButtonText: "未授权",
      });
      this.loadAppList();
    },
    async loadAppList() {
      try {
        this.tableData.loading = true;

        let params = this.searchForm;
        if (!params.searchToken || params.searchToken.length === 0) {
          delete params.searchToken;
        }

        this.appData = await this.$http.doApi('fw-wx-authorizer-list', { wrapper: true }, params);
      } finally {
        this.tableData.loading = false;
      }
    }
  },
  created() {
    this.loadAppList();
  }
}
</script>

<style lang="less">
.authorization-apps {
  padding: 1rem;

  .top-buttons {
    margin-bottom: 1rem;
  }

  .app-item {
    border-bottom: @hair-border-ccc;
    padding: .3rem;
    box-sizing: border-box;

    .head-img {
      width: 60px;
      height: 60px;
      margin-right: .3rem;
      margin-right: 1rem;
    }

    .name-info {
      flex-direction: row;
      margin-right: 1rem;

      .nickname {
        font-weight: 600;
        margin-bottom: .2rem;

        .el-tag {
          margin-right: 2px;
        }
      }

      .signature {
        color: #787878;
        font-size: 10px;
      }
    }

    .principal-info {
      text-align: center;
      margin-right: 1rem;

      .name {
        display: flex;
        justify-content: space-between;
      }
    }

    .develop-info {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      justify-content: center;
      align-items: center;

      .label {
        width: 50px;
        text-align: center;
        color: #000;
        font-weight: 500;
      }

      .value {
        margin-left: 3px;
        width: 150px;
        text-align: left;
      }

      .appid,
      .userName {
        display: flex;
        flex-direction: row;
      }
    }

    .share-info {
      .qr-code {
        width: 60px;
        height: 60px;
      }
    }
  }

}
</style>